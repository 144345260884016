import { round } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDateRange } from "../redux/dateRangeSlice";
import { DataPoint } from "../types/Measurement";
import { lineProps } from "./BasicChart";

export const cardStyle =
  "bg-[#40385c] bg-opacity-50 border-2 border-[#40385c] py-2 text-white";

const getRandom = (items: any[]) =>
  items[Math.floor(Math.random() * items.length)];

interface Message {
  minTemp?: number;
  maxTemp?: number;
  value: string;
  minHours?: number;
  maxHours?: number;
}

const messagesBank: Message[] = [
  {
    minTemp: 140,
    maxTemp: 9999,
    value: "Sauna tulessa!",
  },
  {
    minTemp: 120,
    maxTemp: 140,
    value: "Nahka kärventyy!",
  },
  {
    minTemp: 120,
    maxTemp: 140,
    value: "Kumisaapaslöylyt",
  },
  {
    minTemp: 120,
    maxTemp: 160,
    value: "Exit immediately!",
  },
  {
    minTemp: 105,
    maxTemp: 115,
    value: "K-18",
  },
  {
    minTemp: 110,
    maxTemp: 120,
    value: "Kamiina mansikkana!",
  },
  {
    minTemp: 100,
    maxTemp: 110,
    value: "LÖYLYÄ!",
  },
  {
    minTemp: 100,
    maxTemp: 110,
    value: "LÖYLYÄ!",
  },
  {
    minTemp: 100,
    maxTemp: 9999,
    value: "Hylyn kuuma!",
  },
  {
    minTemp: 94,
    maxTemp: 100,
    value: "Setämiehet saunomaan!",
  },
  {
    minTemp: 60,
    maxTemp: 70,
    value: "Jamo saattaa jo saunoa!",
  },
  {
    minTemp: 90,
    maxTemp: 100,
    value: "Hannun säärikarvat käryää!",
  },
  {
    minTemp: 70,
    maxTemp: 80,
    value: "Nöösipoikien sauna-aika",
  },
  {
    minTemp: 40,
    maxTemp: 60,
    value: "Bastu är varm",
  },
  {
    minTemp: 80,
    maxTemp: 90,
    value: "If you can't stand the heat, get out of the kitchen!",
  },
  {
    minTemp: 50,
    maxTemp: 55,
    value: "Lahdessa jussina",
  },
  {
    minTemp: -30,
    maxTemp: 35,
    value: "Nyt saunaa päälle ja sassiin!",
  },
  {
    minTemp: -50,
    maxTemp: 40,
    value: "Laita sauna lämpeen!",
  },
  {
    minTemp: 30,
    maxTemp: 80,
    value: "Anna saunan lämmetä!",
  },
  {
    minTemp: 30,
    maxTemp: 80,
    value: "Odottavan aika on pitkä!",
  },
  {
    minTemp: 50,
    maxTemp: 53,
    value: "Vielä kerkee lenkille!",
  },
  {
    minTemp: 60,
    maxTemp: 63,
    value: "Keula kohti Kakea!",
  },
  {
    minTemp: 80,
    maxTemp: 85,
    value: "Kohta pääsee kylpemään!",
  },
  {
    minTemp: 70,
    maxTemp: 72,
    value: "Ei vielä vettä kiukaalle.",
  },
  {
    minTemp: 67,
    maxTemp: 70,
    value: "Hampaat kalisee",
  },
  {
    minTemp: 72,
    maxTemp: 75,
    value: "Malttia malttia...",
  },
  {
    minTemp: 105,
    maxTemp: 115,
    value: "Paatsen korvalöylyt!",
  },
  {
    minTemp: 40,
    maxTemp: 80,
    value: "Mummojen kehruulämpö...",
  },
  {
    minTemp: 60,
    maxTemp: 100,
    value: "Kohtuu lämmin...",
  },
  {
    minTemp: 100,
    maxTemp: 110,
    value: "It's getting serious!",
  },
  {
    minTemp: 80,
    maxTemp: 90,
    value: "Ei kai siellä palella tarvi...",
  },
  {
    minTemp: 80,
    maxTemp: 87,
    value: "Reppapöksyt lauteilla :D",
  },
  {
    minTemp: 74,
    maxTemp: 78,
    value: "Sauna level: Noob",
  },
  {
    minTemp: 79,
    maxTemp: 82,
    value: "Sauna level: Beginner",
  },
  {
    minTemp: 83,
    maxTemp: 89,
    value: "Sauna level: Semi",
  },
  {
    minTemp: 90,
    maxTemp: 94,
    value: "Sauna level: Advanced",
  },
  {
    minTemp: 95,
    maxTemp: 100,
    value: "Sauna level: Semi-Bro",
  },
  {
    minTemp: 100,
    maxTemp: 105,
    value: "Sauna level: Expert",
  },
  {
    minTemp: 106,
    maxTemp: 160,
    value: "Sauna level: Brofessional",
  },
  {
    minTemp: 80,
    maxTemp: 90,
    value: "Semmone 4/5",
  },
  {
    minTemp: 93,
    maxTemp: 100,
    value: "Maslakat löylyt",
  },
  {
    maxTemp: 0,
    value: "Sauna Ice",
  },
  {
    minHours: 6,
    maxHours: 12,
    value: "Aamusaunan aika!",
  },
  {
    minHours: 2,
    maxHours: 4,
    minTemp: 80,
    value: "Empsa nukkumaan!",
  },
  {
    minHours: 2,
    maxHours: 4,
    maxTemp: 60,
    value: "Empsa tulee kohta",
  },
];

const getTemperatureMessage = (saunaTemperature: number, time = new Date()) => {
  const newPossibleMessages = messagesBank
    .filter(
      (message) =>
        saunaTemperature >=
          (message?.minTemp !== undefined ? message?.minTemp : -200) &&
        saunaTemperature <
          (message?.maxTemp !== undefined ? message?.maxTemp : 200)
    )
    .filter(
      (message) =>
        time.getHours() >= (message?.minHours || 0) &&
        time.getHours() <= (message?.maxHours || 23)
    );

  return newPossibleMessages;
};

//console.log(getTemperatureMessage(-85, new Date("2025-01-02T05:33:20.273Z")));

const WoodNotification = ({ dataPoints }: { dataPoints: DataPoint[] }) => {
  const [possibleMessages, setPossibleMessages] = useState<Message[]>([]);
  const [displayedMessage, setDisplayedMessage] = useState<
    Message | undefined
  >();

  if (!dataPoints.length) return null;

  const getMessages = (): { messages: Message[]; fill?: boolean } => {
    const saunaTemp = dataPoints[dataPoints.length - 1].sauna_temperature;
    const smokeTemp = dataPoints[dataPoints.length - 1].smoke_temperature;
    const previousSmokeTemp =
      dataPoints[dataPoints.length - 2].smoke_temperature;
    const rockTemp = dataPoints[dataPoints.length - 1].rock_temperature;
    const previousRockTemp = dataPoints[dataPoints.length - 2].rock_temperature;

    if (
      saunaTemp === undefined ||
      smokeTemp === undefined ||
      previousSmokeTemp === undefined ||
      rockTemp === undefined ||
      previousRockTemp === undefined
    ) {
      return { messages: [{ value: "Häikkää häikkää!" }] };
    }

    const fill =
      dataPoints.length > 2 &&
      smokeTemp < previousSmokeTemp &&
      saunaTemp < 105 &&
      smokeTemp > 40;

    const warmingUp =
      saunaTemp < 40 &&
      smokeTemp > 40 &&
      smokeTemp > previousSmokeTemp &&
      rockTemp > previousRockTemp;

    const messages = fill
      ? [
          { value: "Lisää puuta!" },
          { value: "PUITA!" },
          { value: "Klapia pönttöön!" },
          { value: "Pökköö pesään!" },
          { value: "Sehän sammuu!" },
        ]
      : warmingUp
      ? [{ value: "Lämpenee!" }]
      : getTemperatureMessage(saunaTemp);

    return { messages, fill };
  };

  /* useEffect(() => {
    for (let i = -40; i < 200; i++) {
      console.log(i, getTemperatureMessage(i));
    }
  }, []); */

  const { messages, fill } = getMessages();

  if (JSON.stringify(possibleMessages) !== JSON.stringify(messages)) {
    setDisplayedMessage(getRandom(messages));
    setPossibleMessages(messages);
  }

  return (
    <div className={"!mt-0 px-2 flex flex-col items-center " + cardStyle}>
      <div className="flex flex-col ">
        <div className="text-xs opacity-80">Saunan tila</div>
        <div className={`-mt-1 font-bold ${fill ? "text-red-500" : ""}`}>
          {displayedMessage?.value}
        </div>
      </div>
    </div>
  );
};

export default WoodNotification;
